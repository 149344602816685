import _objectWithoutProperties from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "ariaLabel", "headingText", "sideHeadline", "sideShortText", "subHeadingText", "imageSource", "imageAltText", "descriptionText", "classNameHeading", "classNameSubHeading", "classNamePara", "classNameAnchor", "cta", "styleType", "media", "styleTypeAnchor", "dataAnalytics", "backgroundTheme", "srcSetSetting", "isStoryBlade", "isHero", "key", "isGreaterThanPhone", "isCurrentSlide", "categoryTitle"];
var __jsx = React.createElement;
import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import styles from './FiftyFiftyCard.style';
import RichText from '../../atoms/RichText';
import Heading from '../../atoms/Heading';
import Para from '../../atoms/Para';
import Anchor from '../../atoms/Anchor';
import Media from '../../molecules/Media';
// import slugify from 'url-slug';
import urlSlug from 'url-slug';
import { ContentfulLivePreview } from '@contentful/live-preview';
var FiftyFiftyCard = function FiftyFiftyCard(_ref) {
  var _sideShortText$links, _sideShortText$links$, _sideShortText$links2, _sideShortText$links3, _sideShortText$links4, _sideShortText$links5, _descriptionText$link, _descriptionText$link2, _descriptionText$link3, _descriptionText$link4, _descriptionText$link5, _descriptionText$link6;
  var className = _ref.className,
    ariaLabel = _ref.ariaLabel,
    headingText = _ref.headingText,
    sideHeadline = _ref.sideHeadline,
    sideShortText = _ref.sideShortText,
    subHeadingText = _ref.subHeadingText,
    imageSource = _ref.imageSource,
    imageAltText = _ref.imageAltText,
    descriptionText = _ref.descriptionText,
    classNameHeading = _ref.classNameHeading,
    classNameSubHeading = _ref.classNameSubHeading,
    classNamePara = _ref.classNamePara,
    classNameAnchor = _ref.classNameAnchor,
    cta = _ref.cta,
    styleType = _ref.styleType,
    media = _ref.media,
    styleTypeAnchor = _ref.styleTypeAnchor,
    dataAnalytics = _ref.dataAnalytics,
    backgroundTheme = _ref.backgroundTheme,
    srcSetSetting = _ref.srcSetSetting,
    isStoryBlade = _ref.isStoryBlade,
    isHero = _ref.isHero,
    key = _ref.key,
    isGreaterThanPhone = _ref.isGreaterThanPhone,
    isCurrentSlide = _ref.isCurrentSlide,
    categoryTitle = _ref.categoryTitle,
    others = _objectWithoutProperties(_ref, _excluded);
  var _useState = useState(!(isHero && !isCurrentSlide)),
    shouldBeTabbable = _useState[0],
    setShouldBeTabbable = _useState[1];
  var TextCardContainer = useRef(null);
  useEffect(function () {
    var timer;
    if (isHero && TextCardContainer) {
      var newWidth = window.innerWidth;
      // makes all anchors in hero temporarely untabbable while slide is changing
      setShouldBeTabbable(false);
      // the dealy is to give time for slide to finish (the slider takes 1000 ms so I made this 1010)
      var delayInMilliseconds = 1010;
      timer = setTimeout(function () {
        var textCardBoundaries = TextCardContainer.current.getBoundingClientRect();
        setShouldBeTabbable(isCurrentSlide && textCardBoundaries.width > 0 && textCardBoundaries.left >= 0 && textCardBoundaries.right <= newWidth);
      }, delayInMilliseconds);
    }
    return function () {
      clearTimeout(timer);
    };
  }, [TextCardContainer, isCurrentSlide]);
  var showSideText = sideHeadline || sideShortText || false;
  return __jsx("div", {
    className: cn('nva-50-50-card', showSideText && 'nva-50-50-card--sideheadline', className),
    key: key
  }, !showSideText && __jsx("div", {
    className: cn(media !== null && media !== void 0 && media.video ? 'nva-50-50-card__video' : 'nva-50-50-card__image', 'fiftyFiftyImage', 'col-sm-12 col-md-7', media !== null && media !== void 0 && media.video ? 'video' : 'image')
  }, media && __jsx(Media, {
    video: media.video,
    className: "nva-50-50-card__media fiftyFifty",
    imageDetails: media.image,
    videoSetting: {
      fs: 1
    },
    styleType: styleType,
    srcSetSetting: srcSetSetting,
    isHero: isHero,
    isGreaterThanPhone: isGreaterThanPhone
  })), showSideText && __jsx("div", {
    className: "nva-50-50-card__sideheadline col-sm-12 col-md-7"
  }, categoryTitle && __jsx(Para, {
    className: "nva-50-50-card__text-card-category-title u-overline"
  }, categoryTitle), sideHeadline && __jsx(Heading, {
    HeadingType: "h2",
    className: "nva-50-50-card__text-card-heading ".concat(classNameHeading),
    id: "".concat(urlSlug(sideHeadline))
  }, sideHeadline), sideShortText && __jsx(RichText, {
    isHero: isHero,
    shouldBeTabbable: shouldBeTabbable,
    className: "nva-50-50-card__side-short-text ".concat(classNamePara),
    jsonContent: sideShortText.json ? sideShortText.json : sideShortText,
    entriesContent: sideShortText === null || sideShortText === void 0 ? void 0 : (_sideShortText$links = sideShortText.links) === null || _sideShortText$links === void 0 ? void 0 : (_sideShortText$links$ = _sideShortText$links.entries) === null || _sideShortText$links$ === void 0 ? void 0 : _sideShortText$links$.block,
    linksContent: sideShortText === null || sideShortText === void 0 ? void 0 : (_sideShortText$links2 = sideShortText.links) === null || _sideShortText$links2 === void 0 ? void 0 : (_sideShortText$links3 = _sideShortText$links2.entries) === null || _sideShortText$links3 === void 0 ? void 0 : _sideShortText$links3.inline,
    richTextEmbeddedAsset: sideShortText === null || sideShortText === void 0 ? void 0 : (_sideShortText$links4 = sideShortText.links) === null || _sideShortText$links4 === void 0 ? void 0 : (_sideShortText$links5 = _sideShortText$links4.assets) === null || _sideShortText$links5 === void 0 ? void 0 : _sideShortText$links5.block,
    contentfulLivePreview: ContentfulLivePreview.getProps({
      entryId: others === null || others === void 0 ? void 0 : others.entryId,
      fieldId: 'sideShortText'
    })
  })), __jsx("div", {
    ref: TextCardContainer,
    className: "nva-50-50-card__text-card-wrapper fiftyFiftyText col-sm-12  col-md-5"
  }, __jsx("div", {
    className: "nva-50-50-card__text-card textCard"
  }, categoryTitle && !sideHeadline && __jsx(Para, {
    className: "nva-50-50-card__text-card-category-title u-overline"
  }, categoryTitle), headingText && __jsx(Heading, {
    HeadingType: "h2",
    className: "nva-50-50-card__text-card-heading ".concat(classNameHeading),
    id: "".concat(urlSlug(headingText)),
    contentfulLivePreview: ContentfulLivePreview.getProps({
      entryId: others === null || others === void 0 ? void 0 : others.entryId,
      fieldId: "headline"
    })
  }, headingText), subHeadingText && __jsx(Para, {
    className: "nva-50-50-card__text-card-sub-heading ".concat(classNameSubHeading),
    contentfulLivePreview: ContentfulLivePreview.getProps({
      entryId: others === null || others === void 0 ? void 0 : others.entryId,
      fieldId: "subHeadline"
    })
  }, subHeadingText), descriptionText && __jsx(RichText, {
    isHero: isHero,
    shouldBeTabbable: shouldBeTabbable,
    className: "nva-50-50-card__text-card-description ".concat(classNamePara),
    jsonContent: descriptionText.json ? descriptionText.json : descriptionText,
    entriesContent: descriptionText === null || descriptionText === void 0 ? void 0 : (_descriptionText$link = descriptionText.links) === null || _descriptionText$link === void 0 ? void 0 : (_descriptionText$link2 = _descriptionText$link.entries) === null || _descriptionText$link2 === void 0 ? void 0 : _descriptionText$link2.block,
    linksContent: descriptionText === null || descriptionText === void 0 ? void 0 : (_descriptionText$link3 = descriptionText.links) === null || _descriptionText$link3 === void 0 ? void 0 : (_descriptionText$link4 = _descriptionText$link3.entries) === null || _descriptionText$link4 === void 0 ? void 0 : _descriptionText$link4.inline,
    richTextEmbeddedAsset: descriptionText === null || descriptionText === void 0 ? void 0 : (_descriptionText$link5 = descriptionText.links) === null || _descriptionText$link5 === void 0 ? void 0 : (_descriptionText$link6 = _descriptionText$link5.assets) === null || _descriptionText$link6 === void 0 ? void 0 : _descriptionText$link6.block,
    contentfulLivePreview: ContentfulLivePreview.getProps({
      entryId: others === null || others === void 0 ? void 0 : others.entryId,
      fieldId: 'shortText'
    })
  }), (cta === null || cta === void 0 ? void 0 : cta.length) > 0 && __jsx("div", {
    className: cn("nva-50-50-card__text-card-ctas-wrapper", cta.length > 1 ? "nva-50-50-card__text-card-ctas-wrapper--multiple" : "nva-50-50-card__text-card-ctas-wrapper--single")
  }, cta.map(function (ctaItem, index) {
    var _ctaItem$sys;
    return __jsx(Anchor, {
      key: index,
      "data-entry-id": (_ctaItem$sys = ctaItem.sys) === null || _ctaItem$sys === void 0 ? void 0 : _ctaItem$sys.id,
      tabIndex: shouldBeTabbable ? '0' : '-1',
      title: ctaItem.label,
      to: ctaItem.url,
      hyperlinkType: ctaItem.hyperlinkType,
      className: "nva-50-50-card__text-card-anchor ".concat(classNameAnchor),
      ctaBehavior: ctaItem.behavior,
      "aria-label": ctaItem.ariaLabel,
      "data-analytics-type": dataAnalytics && dataAnalytics.type ? dataAnalytics.type : '',
      "data-analytics-value": dataAnalytics && dataAnalytics.value ? dataAnalytics.value : '',
      "data-analytics-variation": dataAnalytics && dataAnalytics.variation ? dataAnalytics.variation : '',
      styleType: index === 0 ? 'primary-anchor' : 'secondary-anchor',
      type: ctaItem.type
    }, ctaItem.label);
  })))));
};
FiftyFiftyCard.defaultProps = {
  className: '',
  styleType: '',
  isHero: false
};
var StyledFiftyFity = styled(FiftyFiftyCard).withConfig({
  componentId: "sc-wb9y8b-0"
})(["", ";"], styles);
export default StyledFiftyFity;
export { FiftyFiftyCard as FiftyFiftyCardVanilla };